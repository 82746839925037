.headline__balloon {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 128px;
  padding: 30px;
  position: relative;
  text-align: center;
  &:after {
    background: inherit;
    content: '';
    display: block;
    height: 20px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 100%;
    transform: translateY(-50%) rotate(45deg);
    width: 20px;
  }
}
.headline {
  &__main {
    font-size: 26px;
    font-weight: bold;
    letter-spacing: .1em;
  }
  &__sub {
    font-size: 20px;
  }
}
.headline__main + .headline__sub {
  margin-top: 20px;
}


@include mq {
  .headline__main {
    font-size: 20px;
    line-height: 1.4;
    + .headline__sub {
      margin-top: 10px;
      font-size: 16px;
      line-height: 1.4;
    }
  }
  .headline__balloon {
    margin: 0 -10px;
    padding: 16px 0;
    &:after {
      width: 16px;
      height: 16px;
    }

  }
}