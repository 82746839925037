.global-header-wrap {
  background: #fff;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  box-shadow: 0 0 5px rgba(0,0,0,.1);
}
.global-header {
  padding: 0px 20px;
  .button + .button {
    margin-left: 16px;
  }
  .navigation__item > a {
    padding: 30px 0;
  }
  .navigation__item + .navigation__item {
    margin-left: 32px;
  }
}


.navigation {
  margin-left: 40px;
  &__item {
    position: relative;
    a {
      color: #333;
      display: block;
    }
  }
}

.navigation {
  &__sub-list {
    opacity: 0;
    pointer-events: none;
    transition: .3s;
    position: absolute;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0,0,0,.8);
    padding: 0 20px;
  }
  &__sub-item {
    text-align: center;
    line-height: 1.4;
    padding: 8px 0;
    a {
      color: #fff;
    }
    + .navigation__sub-item {
      border-top: 1px solid #fff;
    }
  }
}
.navigation__item:hover .navigation__sub-list {
  opacity: 1;
  pointer-events: auto;
}

.global-header__inner {
  > .flex {
    align-items: unset;
  }
  .header-right {
    > .flex {
      align-items: center;
      height: 100%;
    }
  }
}

.user-navigation {
  height: 100%;
  margin-right: -20px;
  margin-left: 32px;
  > .flex {
    height: 100%;
  }
  &__item {
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 5px 0;
    font-size: 14px;
    span {
      display: block;
      width: 100%;
      text-align: center;
    }
    &__gray {
      background: #CCCCCC;
      color: #333;
    }
    &__green {
      background: #00D195;
      color: #fff;
    }
  }
}
