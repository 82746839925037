.body-overlay {
  position: fixed;
  z-index: 1010;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .65);
  opacity: 0;
  pointer-events: none;
  transition: .3s;
  &.is--visible {
    opacity: 1;
    pointer-events: auto;
  }
}


.popup-modal {
  box-sizing: border-box;
  max-width: 800px;
  max-height: 100vh;
  overflow: scroll;
  width: 100%;
  background-color: #fff;
  border: 2px solid #333;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 45px;
  opacity: 0;
  pointer-events: none;
  transition: .3s;
  z-index: 1011;
  
  &.is--visible {
    opacity: 1;
    pointer-events: auto;
  }
  
}

.popup-privacy{
  &__buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: center;
    margin-top: 40px;
  }
  &__button {
    align-items: center;
    box-sizing: border-box;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 18px 28px 18px 48px;
    font-size: 17px;
    margin: 0 20px;
    &:after {
      content: '';
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      height: 1em;
      margin-left: 10px;
      width: 1em;
    }
    &__secondary {
      background: #fff;
      border: 2px solid #333;
      color: #333;
      &:after {
        background-image: url(../../images/dot-arrow_black.png);
      }
    }
    &__primary {
      background: #FD1F77;
      color: #fff;
      &:after {
        background-image: url(../../images/dot-arrow_white.png);
      }
    }
  }
}


@include mq {
  .popup-modal {
    background: rgba(255,255,255,.8);
    border: 0;
    padding: 25px 18px;
    width: 100%;
    left: 0;
    top: 0;
    transform: none;
    p {
      font-size: 13px;
    }
  }

  .popup-privacy{
    &__buttons {
      margin-top: 40px;
    }
    &__button {
      padding: 18px 0;
      font-size: 15px;
      margin: 0 20px;
      width: 172px;
      &:after {
      }
      &__secondary {
      }
      &__primary {
      }
      + .popup-privacy__button {
        margin-top: 25px;
      }
    }
  }

}