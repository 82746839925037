.main-visual {
  min-height: 480px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  &__fill-image {
    .main-visual__image {
      margin-top: 0 !important;
    }
    img {
      bottom:0;
      left:0;
      margin: 0 auto;
      max-width: 1200px;
      right:0;
      position: absolute;
    }
  }
  &__title {
    font-size: 32px;
    letter-spacing: .3em;
    padding-left: .6em;
    width: 100%;
  }
  &__image {
    margin: 0 auto;
    max-width: 160px;
    max-height: 160px;
    width: 100%;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .main-visual__title + .main-visual__image {
    margin-top: 36px;
  }
}

.main-visual__inner {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.main-visual {
  &__option {
    .main-visual__title {
      order: 2;
    }
    .main-visual__image {
      order: 1;
      margin-top: 0 !important;
      margin-bottom: 40px;
      max-height: initial;
      max-width: 508px;
      width: 100%;
    }
  }
  &__privacy {
    .main-visual__title {
      font-size: 47px;
      order: 2;
      letter-spacing: .2em;
      font-weight: normal;
    }
    .main-visual__image {
      order: 1;
      margin-top: 0 !important;
      margin-bottom: 20px;
      width: 70px;
    }
  }
  &__voice {
    .main-visual__image {
      max-width: initial;
      max-height: initial;
      width: 375px;
    }
  }
}



@include mq {
  .main-visual {
    min-height: 240px;
    overflow: hidden;
    &__image {
      max-width: 90px;
      max-height: 90px;
    }
    &__title {
      font-size: 20px;
      line-height: 1.4;
      + .main-visual__image {
        margin-top: 16px !important;
      }
    }
  }
  .main-visual__voice .main-visual__image {
    width: 185px;
  }
  .main-visual__fill-image {
    img {
      width: 130%;
      right: auto;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .main-visual__option .main-visual__image {
    max-width: initial;
    max-height: initial;
    width: 100%;
    padding: 0 10%;
    margin: 0 auto 20px;
  }
  .main-visual__privacy {
    .main-visual__title {
      font-size: 24px;
    }
    .main-visual__image {
      width: 35px;
      margin: 0 auto 8px !important;
    }
  }
}