.voice {
  &__item {
    background: #E5FAF4;
    border-radius: 10px;
    position: relative;
    padding: 40px;
    p {
      position: relative;
      z-index: 1;
    }
    &:after {
      background: #E5FAF4;
      content: '';
      display: block;
      height: 20px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 100%;
      transform: translateY(-50%) rotate(45deg);
      width: 20px;
    }
    &:before {
      content: "VOICE";
      position: absolute;
      bottom: -7px;
      right: 10px;
      font-size: 70px;
      color: #CCF6EA;
      font-family: "こぶりなゴシック W6 JIS2004", "Avenir Next", Verdana, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, Osaka, sans-serif;
      z-index: 0;
    }
  }
}
.voice__item + .voice__item {
  margin-top: 60px;
}
@include mq {
  .voice {
    &__item {
      padding: 18px;
      &:before {
        bottom: -5px;
        font-size: 50px;
      }
      + .voice__item {
        margin-top: 40px;
      }
    }
  }
}