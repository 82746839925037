.table__instructions {
  border-collapse: collapse;
  table-layout: fixed;
  .table__head,
  .table__data {
    padding: 24px;
  }
  .head__fixed-width {
    width: 8%;
  }
  .table__head {
    background: #FFE100;
    border: 1px solid #CCCCCC;
    border-bottom: 0;
  }
  .table__data {
    border: 1px solid #CCCCCC;
    border-top: 0;
  }
}

@include mq {
  .table__instructions {

    .table__head,
    .table__data {
      padding: 10px 12px;
      &:first-child {
        width: 20%;
      }
    }
    .head__fixed-width {
      width: 20%;
    }
  }
}