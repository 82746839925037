@font-face {
  font-family: "din";
  src: url(../../fonts/D-DINCondensed-Bold.otf);
}

$breakpoint: 768px;
@mixin mq {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}


@import "reset";
@import "parts/_animation.scss";
@import "parts/_common.scss";
@import "parts/_fixed-menu.scss";
@import "parts/_footer.scss";
@import "parts/_header.scss";
@import "parts/_headline.scss";
@import "parts/_main-visual.scss";
@import "parts/_menu-trigger.scss";
@import "parts/_modal.scss";
@import "parts/_sp-header.scss";
@import "pages/_faq.scss";
@import "pages/_form.scss";
@import "pages/_instructions.scss";
@import "pages/_option.scss";
@import "pages/_privacy.scss";
@import "pages/_top.scss";
@import "pages/_voice.scss";


body {
  font-family: "こぶりなゴシック W3 JIS2004", "Avenir Next", Verdana, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, Osaka, sans-serif; 
  color: #333;
}
@media screen and (min-width: 769px) {
  body {
    min-width: 1260px;
  }
}

.bold {
  font-family: "こぶりなゴシック W6 JIS2004", "Avenir Next", Verdana, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, Osaka, sans-serif;
}

ul,li {
  list-style-type: none;
}
img {
  max-width: 100%;
  vertical-align: bottom;
}
picture {
  text-align: center;
  display: block;
}
a {
  text-decoration: none;
  color: #005AFF;
  transition: opacity .3s
}
a:hover {
  opacity: .6;
}
.inner {
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
}
.inner__narrow {
  max-width: 820px;
}
.innner__narrow__700 {
  max-width: 720px;
}
.center {
  text-align: center;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.space-between {
  justify-content: space-between;
}

.content {
  margin-top: 100px;
}

@include mq {
  .content {
    margin-top: 24px;
    overflow: hidden;
  }
}

p {
  font-size: 18px;
  line-height: 1.8;
  letter-spacing: .05em;
}

@include mq {
  p {
    font-size: 16px;
    letter-spacing: 0.01em;
  }
}

.visible-phone {
  display: none !important;
}
@include mq {
  .hidden-phone {
    display: none !important;
  }
  .visible-phone {
    display: block !important;
  }
  body {
    padding-bottom: 64px;
  }

}