.menu-trigger {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  position: relative;
  appearance: none;
  width: 24px;
  height: 14px;
  span {
    background: #333;
    display: block;
    width: 100%;
    height: 2px;
    margin: auto;
    position: absolute;
    transition: .3s;
    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      top: 0;
      bottom: 0;
    }
    &:nth-child(3) {
      bottom: 0;
    }
  }

  &.active {
    span {
      &:nth-child(1) {
        transform: translateY(6px) rotate(-45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: translateY(-6px) rotate(45deg);
      }
    }
  }
}

