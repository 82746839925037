.element + .element {
  margin-top: 90px;
}

@include mq {
  .element + .element {
    margin-top: 35px;
  }
}

.button {
  border-radius: 50px;
  box-sizing: border-box;
  display: block;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1;
  padding: 12px 36px 10px 50px;
  position: relative;
  text-align: center;
  .small {
    font-size: 12px;
  }
  .icon {
    display: flex; 
    align-items: center;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
  }


  &__white {
    background: #fff;
    border: 1px solid #333;
    color: #333;
  
  }
  &__black {
    background: #333;
    color: #fff;
    padding: 4px 36px 4px 50px;
    
  }
  &__pink {
    background: #FD1F77;
    color: #fff;
  }

  &__large {
    font-size: 18px;
    padding: 18px 36px 16px 56px;
    .icon {
      left: 20px;
      margin-top: -2px;
    }
  }
  &__arrow {
    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
      height: 16px;
    }
    &.button__white {
      &:after {
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229.549%22%20height%3D%2214.855%22%20viewBox%3D%220%200%209.55%2014.86%22%3E%3Cpath%20d%3D%22M2.12%202.12l5.3%205.3-5.3%205.3%22%20fill%3D%22none%22%20stroke%3D%22%23333%22%20stroke-linecap%3D%22round%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%223%22%2F%3E%3C%2Fsvg%3E');
      }
    }
    &.button__black {
      &:after {
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229.549%22%20height%3D%2214.855%22%20viewBox%3D%220%200%209.55%2014.86%22%3E%3Cpath%20d%3D%22M2.12%202.12l5.3%205.3-5.3%205.3%22%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-linecap%3D%22round%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%223%22%2F%3E%3C%2Fsvg%3E');
      }
    }
    &.button__pink {
      &:after {
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229.549%22%20height%3D%2214.855%22%20viewBox%3D%220%200%209.55%2014.86%22%3E%3Cpath%20d%3D%22M2.12%202.12l5.3%205.3-5.3%205.3%22%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-linecap%3D%22round%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%223%22%2F%3E%3C%2Fsvg%3E');
      }
    }
  }

}


.table {
  border-bottom: 1px solid #999;
  display: table;
  width: 100%;
  &__row {
    display: table-row;
  }
  &__head,
  &__data {
    display: table-cell;
    padding: 8px 0;
    border-top: 1px solid #999;
    padding: 30px;
  }
  &__head {
    box-sizing: border-box;
    font-weight: bold;
    width: 240px;
    background: #E6E6E6;
  }
}

@include mq {
  .table {
    font-size: 12px;
    &__head {
      padding: 14px;
      width: 120px;
      vertical-align: middle;
    }
    &__data {
      padding: 14px;
      line-height: 1.6;
      vertical-align: middle;
    }
  }
}

.link__caption {
  margin-top: 25px;
}