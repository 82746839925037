.footer {
  color: #fff;
  margin-top: 100px;
  background: #FD1F77;
  padding: 36px 0;
  .flex {
    justify-content: space-between;
  }
  &__logo {
    width: 164px;
  }
  .credit-number {
    margin-top: 24px;
    font-size: 16px;
  }
}

.footer-navigation {
  .navigation__item {
    font-size: 16px;
    padding: 10px 0;
    a {
      color: #fff;
      &:before {
        content: '> ';
      }
    }
  }
}

.copyright {
  background: #E6E6E6;
  padding: 8px 0;
  font-size: 13px;
}

@include mq {
  .footer {
    margin-top: 60px;
  }
  .footer-navigation {
    margin-top: 24px;
    .navigation__item {
      padding: 0;
      line-height: 1.4;
    }
  }
}