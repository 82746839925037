.sp-header-wrap {
  background: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,.1);
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  .logo {
    width: 100px;
  }
  .flex {
    justify-content: space-between;
  }
}
.sp-header {
  padding: 12px 0;
}
.sp-menu {
  background: rgba(255,255,255,.9);
  position: fixed;
  z-index: 90;
  top: 0;
  transform: translateY(-100%);
  padding: 75px 25px 35px;
  transition: .3s;
  @at-root .menu-open {
    .sp-menu {
      transform: translateY(0);
    }
  }
  &__item {
    text-align: center;
    a {
      color: #333;
      display: block;
      padding: 10px 0;
    }
    + .sp-menu__item {
      margin-top: 16px;
    }
  }
  .button {
    font-size: 16px;
    padding: 12px 36px 10px 46px;
    margin: auto;
    .small {
      font-size: 10px;
    }
    .icon {
      left: 14px;
      align-items: center;
      img {
        height: auto;
      }
    }

    &__black {
      padding: 6px 36px 6px 46px;
    }
    &__white {

    }
    &__large {
      font-size: 16px;
      padding: 16px 36px 14px 56px;
      margin: 16px auto 0;
    }
  }
  .button-links {
    margin-top: 24px;
    .flex {
      justify-content: space-between;
    }
  }
  .user-navigation {
    margin: 50px auto 0;
    > .flex {
      justify-content: space-around;
    }
    &__item {
      border-radius: 5px;
      padding: 12px 0;
      width: 136px;
      line-height: 1.2;
    }
    .icon {
      + span {
        margin-top: 5px;
      }
    }
  }
}