.fixed-menu {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 75;
  &__list {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    display: flex;
    font-size: 12px;
    a {
      display: flex;
      padding: 8px 0;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      text-align: center;
      width: 100%;
    }
    .small {
      font-size: 10px;
    }
    .icon {
      width: 100%;
      margin-bottom: 5px;
    }
    &:nth-child(1) {
      background: #333;
      width: 25%;
      a {
        color: #fff;
      }
    }
    &:nth-child(2) {
      background: #fff;
      width: 25%;
      a {
        color: #333;
      }
    }
    &:nth-child(3) {
      background: #FD1F77;
      width: 50%;
      font-size: 16px;
      a {
        color: #fff;
      }
    }
  }
}

.to-top {
  position: fixed;
  z-index: 80;
  right: 20px;
  bottom: 20px;
  width: 80px;
  cursor: pointer;
}

@include mq {
  .to-top {
    bottom: 80px;
    right: 10px;
    width: 50px;

  }
}