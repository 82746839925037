@charset "UTF-8";
@font-face {
  font-family: "din";
  src: url(../../fonts/D-DINCondensed-Bold.otf); }

/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

.fade-up {
  opacity: 0;
  transform: translateY(30px);
  transition: .5s;
  transition-timing-function: ease-in-out; }
  .fade-up.active {
    opacity: 1;
    transform: translateY(0); }

.element + .element {
  margin-top: 90px; }

@media screen and (max-width: 768px) {
  .element + .element {
    margin-top: 35px; } }

.button {
  border-radius: 50px;
  box-sizing: border-box;
  display: block;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1;
  padding: 12px 36px 10px 50px;
  position: relative;
  text-align: center; }
  .button .small {
    font-size: 12px; }
  .button .icon {
    display: flex;
    align-items: center;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%); }
  .button__white {
    background: #fff;
    border: 1px solid #333;
    color: #333; }
  .button__black {
    background: #333;
    color: #fff;
    padding: 4px 36px 4px 50px; }
  .button__pink {
    background: #FD1F77;
    color: #fff; }
  .button__large {
    font-size: 18px;
    padding: 18px 36px 16px 56px; }
    .button__large .icon {
      left: 20px;
      margin-top: -2px; }
  .button__arrow:after {
    content: "";
    display: block;
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 16px; }
  .button__arrow.button__white:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229.549%22%20height%3D%2214.855%22%20viewBox%3D%220%200%209.55%2014.86%22%3E%3Cpath%20d%3D%22M2.12%202.12l5.3%205.3-5.3%205.3%22%20fill%3D%22none%22%20stroke%3D%22%23333%22%20stroke-linecap%3D%22round%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%223%22%2F%3E%3C%2Fsvg%3E"); }
  .button__arrow.button__black:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229.549%22%20height%3D%2214.855%22%20viewBox%3D%220%200%209.55%2014.86%22%3E%3Cpath%20d%3D%22M2.12%202.12l5.3%205.3-5.3%205.3%22%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-linecap%3D%22round%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%223%22%2F%3E%3C%2Fsvg%3E"); }
  .button__arrow.button__pink:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229.549%22%20height%3D%2214.855%22%20viewBox%3D%220%200%209.55%2014.86%22%3E%3Cpath%20d%3D%22M2.12%202.12l5.3%205.3-5.3%205.3%22%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-linecap%3D%22round%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%223%22%2F%3E%3C%2Fsvg%3E"); }

.table {
  border-bottom: 1px solid #999;
  display: table;
  width: 100%; }
  .table__row {
    display: table-row; }
  .table__head, .table__data {
    display: table-cell;
    padding: 8px 0;
    border-top: 1px solid #999;
    padding: 30px; }
  .table__head {
    box-sizing: border-box;
    font-weight: bold;
    width: 240px;
    background: #E6E6E6; }

@media screen and (max-width: 768px) {
  .table {
    font-size: 12px; }
    .table__head {
      padding: 14px;
      width: 120px;
      vertical-align: middle; }
    .table__data {
      padding: 14px;
      line-height: 1.6;
      vertical-align: middle; } }

.link__caption {
  margin-top: 25px; }

.fixed-menu {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 75; }
  .fixed-menu__list {
    display: flex;
    flex-wrap: wrap; }
  .fixed-menu__item {
    display: flex;
    font-size: 12px; }
    .fixed-menu__item a {
      display: flex;
      padding: 8px 0;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      text-align: center;
      width: 100%; }
    .fixed-menu__item .small {
      font-size: 10px; }
    .fixed-menu__item .icon {
      width: 100%;
      margin-bottom: 5px; }
    .fixed-menu__item:nth-child(1) {
      background: #333;
      width: 25%; }
      .fixed-menu__item:nth-child(1) a {
        color: #fff; }
    .fixed-menu__item:nth-child(2) {
      background: #fff;
      width: 25%; }
      .fixed-menu__item:nth-child(2) a {
        color: #333; }
    .fixed-menu__item:nth-child(3) {
      background: #FD1F77;
      width: 50%;
      font-size: 16px; }
      .fixed-menu__item:nth-child(3) a {
        color: #fff; }

.to-top {
  position: fixed;
  z-index: 80;
  right: 20px;
  bottom: 20px;
  width: 80px;
  cursor: pointer; }

@media screen and (max-width: 768px) {
  .to-top {
    bottom: 80px;
    right: 10px;
    width: 50px; } }

.footer {
  color: #fff;
  margin-top: 100px;
  background: #FD1F77;
  padding: 36px 0; }
  .footer .flex {
    justify-content: space-between; }
  .footer__logo {
    width: 164px; }
  .footer .credit-number {
    margin-top: 24px;
    font-size: 16px; }

.footer-navigation .navigation__item {
  font-size: 16px;
  padding: 10px 0; }
  .footer-navigation .navigation__item a {
    color: #fff; }
    .footer-navigation .navigation__item a:before {
      content: '> '; }

.copyright {
  background: #E6E6E6;
  padding: 8px 0;
  font-size: 13px; }

@media screen and (max-width: 768px) {
  .footer {
    margin-top: 60px; }
  .footer-navigation {
    margin-top: 24px; }
    .footer-navigation .navigation__item {
      padding: 0;
      line-height: 1.4; } }

.global-header-wrap {
  background: #fff;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }

.global-header {
  padding: 0px 20px; }
  .global-header .button + .button {
    margin-left: 16px; }
  .global-header .navigation__item > a {
    padding: 30px 0; }
  .global-header .navigation__item + .navigation__item {
    margin-left: 32px; }

.navigation {
  margin-left: 40px; }
  .navigation__item {
    position: relative; }
    .navigation__item a {
      color: #333;
      display: block; }

.navigation__sub-list {
  opacity: 0;
  pointer-events: none;
  transition: .3s;
  position: absolute;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  padding: 0 20px; }

.navigation__sub-item {
  text-align: center;
  line-height: 1.4;
  padding: 8px 0; }
  .navigation__sub-item a {
    color: #fff; }
  .navigation__sub-item + .navigation__sub-item {
    border-top: 1px solid #fff; }

.navigation__item:hover .navigation__sub-list {
  opacity: 1;
  pointer-events: auto; }

.global-header__inner > .flex {
  align-items: unset; }

.global-header__inner .header-right > .flex {
  align-items: center;
  height: 100%; }

.user-navigation {
  height: 100%;
  margin-right: -20px;
  margin-left: 32px; }
  .user-navigation > .flex {
    height: 100%; }
  .user-navigation__item {
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 5px 0;
    font-size: 14px; }
    .user-navigation__item span {
      display: block;
      width: 100%;
      text-align: center; }
    .user-navigation__item__gray {
      background: #CCCCCC;
      color: #333; }
    .user-navigation__item__green {
      background: #00D195;
      color: #fff; }

.headline__balloon {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 128px;
  padding: 30px;
  position: relative;
  text-align: center; }
  .headline__balloon:after {
    background: inherit;
    content: '';
    display: block;
    height: 20px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 100%;
    transform: translateY(-50%) rotate(45deg);
    width: 20px; }

.headline__main {
  font-size: 26px;
  font-weight: bold;
  letter-spacing: .1em; }

.headline__sub {
  font-size: 20px; }

.headline__main + .headline__sub {
  margin-top: 20px; }

@media screen and (max-width: 768px) {
  .headline__main {
    font-size: 20px;
    line-height: 1.4; }
    .headline__main + .headline__sub {
      margin-top: 10px;
      font-size: 16px;
      line-height: 1.4; }
  .headline__balloon {
    margin: 0 -10px;
    padding: 16px 0; }
    .headline__balloon:after {
      width: 16px;
      height: 16px; } }

.main-visual {
  min-height: 480px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative; }
  .main-visual__fill-image .main-visual__image {
    margin-top: 0 !important; }
  .main-visual__fill-image img {
    bottom: 0;
    left: 0;
    margin: 0 auto;
    max-width: 1200px;
    right: 0;
    position: absolute; }
  .main-visual__title {
    font-size: 32px;
    letter-spacing: .3em;
    padding-left: .6em;
    width: 100%; }
  .main-visual__image {
    margin: 0 auto;
    max-width: 160px;
    max-height: 160px;
    width: 100%; }
    .main-visual__image img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%; }
  .main-visual .main-visual__title + .main-visual__image {
    margin-top: 36px; }

.main-visual__inner {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

.main-visual__option .main-visual__title {
  order: 2; }

.main-visual__option .main-visual__image {
  order: 1;
  margin-top: 0 !important;
  margin-bottom: 40px;
  max-height: initial;
  max-width: 508px;
  width: 100%; }

.main-visual__privacy .main-visual__title {
  font-size: 47px;
  order: 2;
  letter-spacing: .2em;
  font-weight: normal; }

.main-visual__privacy .main-visual__image {
  order: 1;
  margin-top: 0 !important;
  margin-bottom: 20px;
  width: 70px; }

.main-visual__voice .main-visual__image {
  max-width: initial;
  max-height: initial;
  width: 375px; }

@media screen and (max-width: 768px) {
  .main-visual {
    min-height: 240px;
    overflow: hidden; }
    .main-visual__image {
      max-width: 90px;
      max-height: 90px; }
    .main-visual__title {
      font-size: 20px;
      line-height: 1.4; }
      .main-visual__title + .main-visual__image {
        margin-top: 16px !important; }
  .main-visual__voice .main-visual__image {
    width: 185px; }
  .main-visual__fill-image img {
    width: 130%;
    right: auto;
    left: 50%;
    transform: translateX(-50%); }
  .main-visual__option .main-visual__image {
    max-width: initial;
    max-height: initial;
    width: 100%;
    padding: 0 10%;
    margin: 0 auto 20px; }
  .main-visual__privacy .main-visual__title {
    font-size: 24px; }
  .main-visual__privacy .main-visual__image {
    width: 35px;
    margin: 0 auto 8px !important; } }

.menu-trigger {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  position: relative;
  appearance: none;
  width: 24px;
  height: 14px; }
  .menu-trigger span {
    background: #333;
    display: block;
    width: 100%;
    height: 2px;
    margin: auto;
    position: absolute;
    transition: .3s; }
    .menu-trigger span:nth-child(1) {
      top: 0; }
    .menu-trigger span:nth-child(2) {
      top: 0;
      bottom: 0; }
    .menu-trigger span:nth-child(3) {
      bottom: 0; }
  .menu-trigger.active span:nth-child(1) {
    transform: translateY(6px) rotate(-45deg); }
  .menu-trigger.active span:nth-child(2) {
    opacity: 0; }
  .menu-trigger.active span:nth-child(3) {
    transform: translateY(-6px) rotate(45deg); }

.body-overlay {
  position: fixed;
  z-index: 1010;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.65);
  opacity: 0;
  pointer-events: none;
  transition: .3s; }
  .body-overlay.is--visible {
    opacity: 1;
    pointer-events: auto; }

.popup-modal {
  box-sizing: border-box;
  max-width: 800px;
  max-height: 100vh;
  overflow: scroll;
  width: 100%;
  background-color: #fff;
  border: 2px solid #333;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 45px;
  opacity: 0;
  pointer-events: none;
  transition: .3s;
  z-index: 1011; }
  .popup-modal.is--visible {
    opacity: 1;
    pointer-events: auto; }

.popup-privacy__buttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: center;
  margin-top: 40px; }

.popup-privacy__button {
  align-items: center;
  box-sizing: border-box;
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 18px 28px 18px 48px;
  font-size: 17px;
  margin: 0 20px; }
  .popup-privacy__button:after {
    content: '';
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    height: 1em;
    margin-left: 10px;
    width: 1em; }
  .popup-privacy__button__secondary {
    background: #fff;
    border: 2px solid #333;
    color: #333; }
    .popup-privacy__button__secondary:after {
      background-image: url(../../images/dot-arrow_black.png); }
  .popup-privacy__button__primary {
    background: #FD1F77;
    color: #fff; }
    .popup-privacy__button__primary:after {
      background-image: url(../../images/dot-arrow_white.png); }

@media screen and (max-width: 768px) {
  .popup-modal {
    background: rgba(255, 255, 255, 0.8);
    border: 0;
    padding: 25px 18px;
    width: 100%;
    left: 0;
    top: 0;
    transform: none; }
    .popup-modal p {
      font-size: 13px; }
  .popup-privacy__buttons {
    margin-top: 40px; }
  .popup-privacy__button {
    padding: 18px 0;
    font-size: 15px;
    margin: 0 20px;
    width: 172px; }
    .popup-privacy__button + .popup-privacy__button {
      margin-top: 25px; } }

.sp-header-wrap {
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100; }
  .sp-header-wrap .logo {
    width: 100px; }
  .sp-header-wrap .flex {
    justify-content: space-between; }

.sp-header {
  padding: 12px 0; }

.sp-menu {
  background: rgba(255, 255, 255, 0.9);
  position: fixed;
  z-index: 90;
  top: 0;
  transform: translateY(-100%);
  padding: 75px 25px 35px;
  transition: .3s; }
  .menu-open .sp-menu {
    transform: translateY(0); }
  .sp-menu__item {
    text-align: center; }
    .sp-menu__item a {
      color: #333;
      display: block;
      padding: 10px 0; }
    .sp-menu__item + .sp-menu__item {
      margin-top: 16px; }
  .sp-menu .button {
    font-size: 16px;
    padding: 12px 36px 10px 46px;
    margin: auto; }
    .sp-menu .button .small {
      font-size: 10px; }
    .sp-menu .button .icon {
      left: 14px;
      align-items: center; }
      .sp-menu .button .icon img {
        height: auto; }
    .sp-menu .button__black {
      padding: 6px 36px 6px 46px; }
    .sp-menu .button__large {
      font-size: 16px;
      padding: 16px 36px 14px 56px;
      margin: 16px auto 0; }
  .sp-menu .button-links {
    margin-top: 24px; }
    .sp-menu .button-links .flex {
      justify-content: space-between; }
  .sp-menu .user-navigation {
    margin: 50px auto 0; }
    .sp-menu .user-navigation > .flex {
      justify-content: space-around; }
    .sp-menu .user-navigation__item {
      border-radius: 5px;
      padding: 12px 0;
      width: 136px;
      line-height: 1.2; }
    .sp-menu .user-navigation .icon + span {
      margin-top: 5px; }

.din {
  font-family: 'Oswald', sans-serif; }

.faq__question {
  align-items: center;
  background: #00D195;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 24px; }

.faq__answer {
  background: #D9F8EF;
  padding: 36px;
  font-size: 18px;
  line-height: 1.8; }

.question__text {
  flex-grow: 1;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.1em;
  padding-left: 36px; }

.question-icon {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  border: 3px solid #fff;
  border-radius: 10px;
  font-size: 40px;
  color: #fff;
  padding-bottom: 4px; }

.question-arrow {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid #fff;
  position: relative;
  transform: rotate(-90deg);
  transition: .3s; }
  .question-arrow:before {
    content: "";
    display: block;
    border: 3px solid #fff;
    width: 14px;
    height: 14px;
    border-top: 0;
    border-left: 0;
    transform: translateY(-50%) rotate(45deg);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: calc(50% - 3px); }

.active .question-arrow {
  transform: rotate(0deg); }

.faq__item + .faq__item {
  margin-top: 48px; }

.toggle-container {
  transition: height 0.35s ease-in-out;
  overflow: hidden; }

.toggle-container:not(.active) {
  display: none; }

@media screen and (max-width: 768px) {
  .faq__question {
    padding: 12px 14px; }
  .faq__answer {
    font-size: 16px;
    padding: 12px 14px; }
  .faq__item + .faq__item {
    margin-top: 24px; }
  .question-icon {
    font-size: 20px;
    height: 38px;
    width: 38px;
    border-width: 2px; }
  .question__text {
    box-sizing: border-box;
    font-size: 16px;
    padding-left: 8px;
    width: calc(100% - 38px - 40px);
    flex-grow: unset; }
  .question-arrow {
    border-width: 2px;
    height: 30px;
    width: 30px;
    box-sizing: border-box; }
    .question-arrow:before {
      height: 9px;
      width: 9px;
      top: calc(50% - 2px);
      border-width: 2px; } }

.contact-header {
  border-top: 8px solid #FD1F77;
  padding: 80px 0 60px;
  font-size: 30px; }
  .contact-header .flex {
    justify-content: center;
    align-items: center; }

.contact-main-visual {
  margin-top: 50px; }

.contact__head {
  line-height: 1;
  margin-left: 100px; }

.contact-mv {
  padding: 73px 0 56px; }

.contact-body {
  padding: 80px 0 100px;
  background: #F2F2F2; }

.contact__flow {
  text-align: center;
  margin-bottom: 40px; }

.form-table {
  border-spacing: 0px 30px;
  border-collapse: separate;
  margin-bottom: 80px; }
  .form-table th {
    padding-right: 20px;
    font-weight: normal;
    width: 180px;
    padding-top: 7px;
    vertical-align: top;
    text-align: left; }
    .form-table th .label-required,
    .form-table th .label-optional {
      margin-left: 5px;
      margin-right: 0;
      float: right;
      line-height: 1;
      display: inline-block; }
  .form-table .label-required {
    background: #FD1F77;
    color: #fff;
    line-height: 1;
    padding: 4px 4px 2px;
    margin-right: 5px;
    display: inline-block; }
  .form-table .label-optional {
    background: #005AFF;
    color: #fff;
    line-height: 1;
    padding: 4px 4px 2px;
    margin-right: 5px;
    display: inline-block; }
  .form-table .label-not-required {
    margin-right: 5px;
    padding: 4px 4px 2px; }
    .form-table .label-not-required:before {
      content: "　　";
      color: transparent; }
  .form-table .input {
    border: 2px solid #B3B3B3;
    border-radius: 5px;
    box-sizing: border-box;
    padding: .2rem 1rem;
    width: 47%;
    background: #fff;
    display: inline-block;
    font-size: 18px;
    line-height: 2; }
  .form-table .input__max-width {
    width: 100%; }
  .form-table .input__width80 {
    width: 80%; }
  .form-table input[type="radio"] {
    margin-right: 3px; }
  .form-table .flex {
    justify-content: space-between; }
  .form-table .birth-wrap {
    margin-bottom: 10px; }
    .form-table .birth-wrap .input-wrap {
      width: 33%;
      width: calc(100% / 3 - 10px);
      margin-right: 10px; }
      .form-table .birth-wrap .input-wrap .input {
        margin-right: 5px;
        width: calc(100% - 1em - 5px); }
  .form-table .pref,
  .form-table .zipcode {
    width: 200px; }
  .form-table .address-input {
    margin-top: 10px; }
  .form-table label {
    display: inline; }
  .form-table textarea {
    width: 100% !important;
    height: 300px; }

.cta__btn {
  font-size: 0;
  vertical-align: top; }

#back-sp {
  display: none; }

#back-sp,
#back {
  border-bottom-color: #0086BC;
  margin-right: 60px; }

.link-toppage {
  margin: 60px 0 80px; }
  .link-toppage a {
    color: #000000; }

#formWrap + .footer,
.contact-body + .footer {
  margin-top: 0; }

@media screen and (max-width: 768px) {
  .contact-header {
    padding: 30px 0 20px; }
  .contact-logo img {
    width: 100px;
    display: block; }
  .contact__head {
    font-size: 16px;
    margin-left: 20px; }
    .contact__head img {
      height: 20px;
      width: auto; }
  .contact-mv {
    padding: 20px 40px; }
  .contact-main-visual {
    margin-top: 30px; }
  .contact-body {
    padding: 25px 0 40px; }
  .contact__flow {
    margin-bottom: 25px; }
  .form-table {
    margin-bottom: 20px;
    border-spacing: 0;
    table-layout: fixed;
    width: 100%; }
    .form-table tr,
    .form-table th,
    .form-table td {
      display: block; }
    .form-table th {
      width: auto;
      margin-bottom: 1rem; }
      .form-table th .label-required,
      .form-table th .label-optional {
        float: none; }
    .form-table td {
      padding-top: 5px; }
    .form-table .input {
      width: 100%;
      font-size: 16px !important;
      margin-bottom: 1rem; }
    .form-table .input.radio {
      width: auto;
      margin-right: 10px; }
    .form-table select {
      margin-bottom: 1rem; }
    .form-table .flex {
      justify-content: flex-start; }
    .form-table .zipcode {
      width: 50%; }
    .form-table .label-required {
      margin-left: 5px;
      margin-right: 0; }
  #back-sp {
    margin-right: 0;
    margin-top: 20px;
    display: inline-block; }
  .confirm .form-table {
    width: 100%;
    margin-bottom: 20px; }
    .confirm .form-table tr {
      border-top: 1px solid #ddd;
      padding: 10px 0; }
      .confirm .form-table tr:first-child {
        border-top: 0; }
    .confirm .form-table th {
      margin-bottom: 0; }
  .link-toppage {
    margin: 30px 0; } }

.cta {
  background-size: auto auto;
  background-color: #fde8ef;
  background-image: -webkit-repeating-linear-gradient(154deg, transparent, transparent 6px, #fef4f7 6px, #fef4f7 12px);
  background-image: repeating-linear-gradient(-64deg, transparent, transparent 6px, #fef4f7 6px, #fef4f7 12px);
  padding: 7rem 0; }

.conclusion {
  margin-bottom: 5rem; }

.cta__logo {
  margin-bottom: 3rem; }

.cta__privilege {
  margin-top: 3rem;
  margin-bottom: 5.5rem; }

.cta__btn a {
  border-bottom: 6px solid #931B5E;
  display: inline-block;
  border-radius: 10px; }

.cta__btn a:hover {
  border-bottom: 0;
  -webkit-transform: translateY(6px);
  transform: translateY(6px); }

.table__instructions {
  border-collapse: collapse;
  table-layout: fixed; }
  .table__instructions .table__head,
  .table__instructions .table__data {
    padding: 24px; }
  .table__instructions .head__fixed-width {
    width: 8%; }
  .table__instructions .table__head {
    background: #FFE100;
    border: 1px solid #CCCCCC;
    border-bottom: 0; }
  .table__instructions .table__data {
    border: 1px solid #CCCCCC;
    border-top: 0; }

@media screen and (max-width: 768px) {
  .table__instructions .table__head,
  .table__instructions .table__data {
    padding: 10px 12px; }
    .table__instructions .table__head:first-child,
    .table__instructions .table__data:first-child {
      width: 20%; }
  .table__instructions .head__fixed-width {
    width: 20%; } }

.headline__border {
  border: 4px solid #005AFF;
  color: #005AFF;
  line-height: 1.6;
  letter-spacing: .1em;
  padding: 32px 0;
  text-align: center;
  font-size: 26px; }

.closed .accordion__title {
  background: #666666; }

.accordion__title {
  align-items: center;
  background: #005AFF;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 24px; }

.accordion__body {
  background: #F2F2F2;
  padding: 36px 36px 60px;
  font-size: 18px;
  line-height: 1.8; }

.accordion__text {
  flex-grow: 1;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.1em;
  padding-left: 36px; }

.accordion__list {
  margin-top: 40px; }

.accordion-icon {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px; }

.accordion-arrow {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #fff;
  position: relative;
  transform: rotate(-90deg);
  transition: .3s; }
  .accordion-arrow:before {
    content: "";
    display: block;
    border: 2px solid #fff;
    width: 14px;
    height: 14px;
    border-top: 0;
    border-left: 0;
    transform: translateY(-50%) rotate(45deg);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: calc(50% - 3px); }

.active .accordion-arrow {
  transform: rotate(0deg); }

.accordion__item + .accordion__item {
  margin-top: 50px; }

.price {
  font-size: 20px; }

.price-title {
  border: 2px solid #000000;
  border-radius: 5px;
  letter-spacing: .2em;
  line-height: 1;
  padding: 18px 20px;
  margin-right: 20px; }

.price-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 50px; }

.price__num {
  font-size: 26px;
  margin-right: 5px; }

.price__tax {
  font-size: 16px; }

.price__item {
  line-height: 1.4; }

.red {
  color: #FD1F77; }

.option-link__list {
  font-size: 16px;
  display: inline-block;
  margin-top: 70px;
  text-align: left; }
  .option-link__list a {
    color: #333; }

.external-link {
  display: flex;
  align-items: center;
  line-height: 1.8; }
  .external-link:before {
    content: "";
    display: block;
    width: 18px;
    height: 16px;
    background: url(../../images/pc/option/icon-externallink.png) no-repeat center center/contain;
    margin-right: 5px; }

.align-top {
  align-items: flex-start; }
  .align-top .price-title {
    margin-top: 10px; }

.inline__align-left {
  display: inline-block;
  margin-top: 56px;
  text-align: left; }

.anchor-nav {
  margin-top: 40px;
  margin-bottom: 100px; }
  .anchor-nav__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -40px -20px 0; }
  .anchor-nav__item {
    font-size: 22px;
    width: 50%;
    padding: 0 20px;
    margin-top: 40px;
    box-sizing: border-box; }
    .anchor-nav__item a {
      background: #E6E6E6;
      color: #333;
      display: block;
      padding: 24px 0;
      text-align: center;
      border-radius: 8px;
      transition: .3s; }
      .anchor-nav__item a:hover {
        background: #005AFF;
        color: #fff; }

@media screen and (max-width: 768px) {
  .section__title__full {
    margin: 0 -10px; }
  .anchor-nav {
    margin-bottom: 30px; }
    .anchor-nav__item {
      font-size: 16px;
      margin-top: 24px;
      width: 80%; }
  .headline__border {
    font-size: 18px;
    padding: 20px 0; }
  .accordion__list {
    margin-top: 12px; }
  .accordion__title {
    padding: 12px; }
  .accordion__text {
    font-size: 18px;
    padding-left: 8px; }
    .accordion__text small {
      display: block;
      font-size: 14px; }
  .accordion__body {
    padding: 14px 14px 45px; }
  .accordion__item + .accordion__item {
    margin-top: 12px; }
  .accordion-icon {
    width: 25px;
    height: 25px; }
  .accordion-arrow {
    width: 25px;
    height: 25px;
    border-width: 1px; }
    .accordion-arrow:before {
      border-width: 1px;
      width: 9px;
      height: 9px;
      top: calc(50% - 2px); }
  .price-title {
    margin-right: 0; }
  .price-wrap {
    margin-top: 18px; }
  .price {
    font-size: 16px;
    margin-top: 16px;
    width: 100%; }
    .price__item {
      text-align: center; }
      .price__item + .price__item {
        margin-top: 8px; }
    .price__num {
      font-size: 22px; }
    .price__tax {
      font-size: 12px; }
  .option-link__list {
    margin-top: 40px; }
  .option-link__item {
    font-size: 13px; }
    .option-link__item + .option-link__item {
      margin-top: 5px; }
  .external-link {
    display: block; }
    .external-link:before {
      display: inline-block;
      width: 13px;
      height: 12px;
      margin-top: 5px; } }

.din .main-visual__title {
  font-family: 'Oswald', sans-serif; }

.align-right {
  text-align: right; }

.signature {
  line-height: 1.8;
  margin-top: 50px; }

.date {
  font-size: 14px; }

.privacy__list dt {
  border-bottom: 1px solid #333;
  font-size: 20px;
  padding-bottom: 14px;
  margin-bottom: 8px; }

.privacy__list dd p {
  font-size: 16px; }

.privacy__list dd + dt {
  margin-top: 40px; }

.privacy__head {
  border-radius: 16px;
  font-size: 28px;
  text-align: center;
  padding: 40px;
  background: #E6E6E6;
  margin-bottom: 40px; }

@media screen and (max-width: 768px) {
  .privacy__head {
    font-size: 18px;
    padding: 30px 0;
    border-radius: 10px; }
  .privacy__list dt {
    font-size: 18px; }
  .privacy__list dd + dt {
    margin-top: 24px; } }

.main-visual__top {
  background: repeating-conic-gradient(#F7F7F7, #f7f7f7 3deg, #F0F0F0 3deg 6deg);
  padding: 16px 0 0; }

.amount {
  background: #FD1F77;
  padding: 32px 0; }

.line {
  padding-bottom: .15em;
  background: linear-gradient(#FFE100, #FFE100) 0 50%/10% 30% no-repeat;
  transition: background .8s;
  transition-timing-function: linear;
  text-decoration: none; }

.line.active {
  background-size: 100% 35%; }

.news {
  padding: 100px 0 50px; }
  .news__container {
    border: 2px solid #333;
    border-radius: 15px;
    padding: 50px 45px 40px;
    position: relative; }
  .news__head {
    background: #FFE600;
    border: 2px solid #333;
    border-radius: 30px;
    display: inline-block;
    font-size: 24px;
    left: 50%;
    letter-spacing: .1em;
    min-width: 220px;
    padding: 6px 0 4px;
    position: absolute;
    text-align: center;
    top: 0;
    transform: translate(-50%, -50%);
    text-transform: uppercase; }
  .news__item {
    display: flex;
    flex-wrap: wrap; }
  .news__date {
    box-sizing: border-box;
    font-weight: bold;
    letter-spacing: .2em;
    padding-right: 10px;
    width: 150px; }
  .news__title {
    font-size: 20px;
    letter-spacing: .2em; }
    .news__title + p {
      margin-top: 16px; }
  .news__body {
    width: calc(100% - 150px); }
    .news__body p {
      font-size: 16px; }

.catch {
  padding: 100px 0;
  position: relative;
  z-index: 1; }
  .catch__title {
    font-size: 40px;
    letter-spacing: .2em;
    line-height: 1.6; }
  .catch__image {
    margin-top: 70px; }
  .catch:after {
    background: #fff;
    bottom: 0;
    content: '';
    display: block;
    height: 30px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    transform: translateY(50%) rotate(45deg);
    width: 30px; }

.issues {
  background: #F2F2F2;
  padding: 160px 0;
  position: relative;
  z-index: 0; }
  .issues__item {
    align-items: center;
    display: flex;
    flex-wrap: wrap; }
    .issues__item:before {
      background: url(../../images/pc/top/batsu.png) no-repeat center center/contain;
      content: "";
      display: block;
      height: 90px;
      width: 90px; }
    .issues__item + .issues__item {
      margin-top: 60px; }
  .issues__item-inner {
    box-sizing: border-box;
    width: calc(100% - 90px);
    padding-left: 12px; }
  .issues__title {
    font-size: 28px; }
  .issues__body {
    margin-top: 20px; }

.section__bottom-image {
  margin-top: 60px; }

.wakaranai__text {
  font-size: 40px;
  line-height: 1.6;
  margin-top: 40px;
  letter-spacing: .1em; }

.merit {
  background: #FFE9F1;
  padding-bottom: 100px; }
  .merit .section__title {
    background: #FD1F77;
    position: relative; }
    .merit .section__title img {
      margin-top: -1.4%; }
    .merit .section__title:after {
      background: #F2F2F2;
      content: '';
      display: block;
      height: 30px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateY(-50%) rotate(45deg);
      width: 30px; }
  .merit__items {
    margin-top: 90px; }
  .merit__item {
    background: #fff;
    border-radius: 20px;
    padding: 56px 60px; }
    .merit__item + .merit__item {
      margin-top: 50px; }
  .merit__title {
    font-size: 36px;
    line-height: 1.6;
    margin-top: 50px;
    letter-spacing: .1em; }
  .merit__subtitle {
    font-size: 28px;
    margin-top: 20px; }
  .merit__image {
    margin-top: 50px; }
  .merit__body {
    margin-top: 70px; }

.service {
  background: #FFFCE5;
  padding-bottom: 140px; }
  .service .section__title {
    background: #FFE600; }
    .service .section__title img {
      margin-top: -1.4%; }
  .service .headline__border {
    background-image: radial-gradient(#f1f1f1 20%, transparent 20%), radial-gradient(#f1f1f1 20%, transparent 20%);
    background-position: 0 0, 10px 10px;
    background-size: 20px 20px;
    border-color: #FFE600;
    background-color: #fff;
    color: #333;
    font-size: 30px;
    padding: 50px 0; }
  .service__content {
    margin-top: 90px; }
  .service .section__block + .section__block {
    margin-top: 140px; }
  .service .section__body {
    margin-top: 50px; }
  .service__table {
    font-size: 22px;
    width: 100%;
    margin-top: 40px; }
    .service__table th, .service__table td {
      padding: 16px; }
    .service__table th {
      background: #FFE600;
      border-bottom: 3px solid #fff; }
    .service__table td {
      background: #fff;
      border-bottom: 3px solid #CCCCCC; }
    .service__table tr:last-child th, .service__table tr:last-child td {
      border-bottom: 0; }
  .service .note {
    margin-top: 20px; }
  .service__links {
    display: inline-block;
    margin-top: 40px;
    padding: 20px;
    background: #fff;
    border: 2px solid #ccc; }
    .service__links .option-link__list {
      margin-top: 20px; }
  .service .button__border {
    margin-top: 90px; }

.button__border a {
  color: #333;
  display: block;
  padding: 30px 0;
  background: #fff;
  border: 4px solid #333;
  border-radius: 60px;
  font-size: 22px;
  letter-spacing: .1em;
  max-width: 480px;
  margin: 0 auto;
  text-align: center; }

.button__border small {
  font-size: 0.9em;
  margin-top: 8px;
  display: block; }

.button__border.pink a {
  background: #FD1F77; }

.note p {
  font-size: 12px;
  letter-spacing: 0; }

@keyframes float {
  0% {
    transform: translateY(0); }
  25% {
    transform: translateY(5px); }
  50% {
    transform: translateY(0); }
  75% {
    transform: translateY(-5px); } }

.tokuten {
  background: #FFE9F1;
  padding-bottom: 100px; }
  .tokuten .section__title {
    background: #FD1F77; }
    .tokuten .section__title img {
      margin-top: -1.4%; }
  .tokuten__head {
    margin: 90px auto 30px;
    animation: float 3s infinite linear; }
  .tokuten__item {
    background: #fff;
    border-radius: 20px;
    padding: 56px 60px; }
    .tokuten__item + .tokuten__item {
      margin-top: 50px; }
  .tokuten__title-wrap {
    align-items: center;
    display: flex;
    flex-wrap: wrap; }
  .tokuten__icon {
    display: block;
    height: 102px;
    width: 102px; }
  .tokuten__title {
    box-sizing: border-box;
    font-size: 30px;
    line-height: 1.4;
    letter-spacing: .1em;
    padding-left: 12px;
    width: calc(100% - 102px); }
  .tokuten__image {
    margin-top: 70px; }
  .tokuten__body {
    margin-top: 70px; }

.flow {
  background: #E5EEFF;
  padding-bottom: 100px; }
  .flow .section__title {
    background: #005AFF; }
    .flow .section__title img {
      margin-top: -1.4%; }
  .flow__items {
    margin-top: 90px; }
  .flow__item {
    background: #fff;
    border: 3px solid #333;
    border-top: 0;
    padding: 50px;
    position: relative; }
    .flow__item + .flow__item {
      margin-top: 70px; }
    .flow__item:after {
      border-color: #333 transparent transparent transparent;
      border-style: solid;
      border-width: 20px 20px 0 20px;
      bottom: 0;
      content: '';
      display: block;
      height: 0;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      transform: translateY(100%);
      width: 0; }
    .flow__item:last-child:after {
      display: none; }
  .flow__title {
    font-size: 32px;
    padding: 30px 0;
    background: #005AFF;
    border-radius: 50px;
    color: #fff;
    letter-spacing: .1em; }
  .flow__subtitle {
    font-size: 32px;
    letter-spacing: .1em;
    margin-top: 50px; }
  .flow__image {
    margin-top: 60px; }
  .flow__body {
    margin-top: 60px; }

.closure {
  padding: 145px 0 0; }
  .closure .button__border {
    margin-top: 50px; }

.tel {
  margin-top: 180px; }

@media screen and (max-width: 768px) {
  .main-visual.main-visual__top {
    padding: 16px 10px 20px; }
  .amount {
    padding: 16px 20px; }
  .news {
    padding: 34px 0 0; }
    .news__head {
      font-size: 14px;
      min-width: 125px; }
    .news__container {
      border-radius: 7px;
      padding: 30px 25px 20px; }
    .news__date {
      font-size: 14px;
      padding: 0;
      text-align: center;
      width: 100%; }
    .news__body {
      width: 100%; }
      .news__body p {
        font-size: 14px; }
    .news__title {
      font-size: 17px;
      line-height: 1.4;
      margin-top: 10px; }
      .news__title + p {
        margin-top: 10px; }
  .catch {
    padding: 50px 0; }
    .catch__title {
      font-size: 20px; }
    .catch__image {
      margin-top: 25px; }
    .catch:after {
      height: 20px;
      width: 20px; }
  .issues {
    padding: 50px 0; }
    .issues__item {
      display: block; }
      .issues__item:before {
        height: 58px;
        margin: 0 auto;
        width: 58px; }
      .issues__item + .issues__item {
        margin-top: 50px; }
    .issues__item-inner {
      padding: 0;
      width: 100%; }
    .issues__title {
      font-size: 20px;
      margin-top: 20px;
      text-align: center; }
  .wakaranai__text {
    font-size: 20px;
    margin-top: 20px; }
  .section__title img {
    margin-top: 0 !important; }
  .section__title .inner {
    padding: 0; }
  .merit {
    padding-bottom: 24px; }
    .merit .section__title:after {
      height: 20px;
      width: 20px; }
    .merit__items {
      margin-top: 25px; }
    .merit__item {
      border-radius: 10px;
      padding: 24px 10px; }
      .merit__item + .merit__item {
        margin-top: 25px; }
    .merit__num img {
      width: 60px; }
    .merit__title {
      font-size: 18px;
      margin-top: 25px; }
    .merit__subtitle {
      font-size: 14px;
      margin-top: 8px; }
    .merit__image {
      margin-top: 35px; }
    .merit__body {
      margin-top: 35px; }
  .service {
    padding-bottom: 25px; }
    .service__content {
      margin-top: 25px; }
    .service .headline__border {
      font-size: 17px;
      padding: 30px 0; }
    .service .section__body {
      margin-top: 20px; }
    .service .button__border {
      margin-top: 25px; }
    .service .section__block + .section__block {
      margin-top: 45px; }
    .service__table {
      font-size: 14px; }
      .service__table th, .service__table td {
        vertical-align: middle;
        line-height: 1.4;
        vertical-align: middle;
        border-width: 1px; }
      .service__table td {
        white-space: nowrap; }
    .service__links {
      margin-top: 20px;
      padding: 16px 16px; }
      .service__links p {
        font-size: 12px; }
  .button__border a {
    border-width: 1px;
    font-size: 17px;
    padding: 20px 0;
    width: 90%; }
  .tokuten {
    padding-bottom: 24px; }
    .tokuten__head {
      margin: 18px auto 16px; }
      .tokuten__head img {
        width: 125px; }
    .tokuten__item {
      border-radius: 10px;
      padding: 24px 10px; }
      .tokuten__item + .tokuten__item {
        margin-top: 24px; }
    .tokuten__title-wrap {
      display: block; }
    .tokuten__icon {
      width: 60px;
      height: auto;
      margin: 0 auto; }
    .tokuten__title {
      font-size: 22px;
      margin: 16px 0 0;
      padding: 0;
      text-align: center;
      width: 100%; }
    .tokuten__image {
      margin-top: 30px; }
    .tokuten__body {
      margin-top: 35px; }
  .flow {
    padding-bottom: 24px; }
    .flow__items {
      margin-top: 25px; }
    .flow__item {
      padding: 22px; }
      .flow__item + .flow__item {
        margin-top: 30px; }
      .flow__item:after {
        border-width: 14px 14px 0 14px; }
    .flow__title {
      font-size: 18px;
      padding: 12px 0; }
    .flow__subtitle {
      font-size: 16px;
      letter-spacing: .0;
      margin-top: 20px; }
    .flow__image {
      margin-top: 35px; }
      .flow__image img {
        max-width: 270px;
        max-height: 107px;
        width: auto;
        height: auto; }
    .flow__body {
      margin-top: 35px; }
  .closure {
    padding: 35px 0 0; }
  .tel {
    margin-top: 55px; } }

.voice__item {
  background: #E5FAF4;
  border-radius: 10px;
  position: relative;
  padding: 40px; }
  .voice__item p {
    position: relative;
    z-index: 1; }
  .voice__item:after {
    background: #E5FAF4;
    content: '';
    display: block;
    height: 20px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 100%;
    transform: translateY(-50%) rotate(45deg);
    width: 20px; }
  .voice__item:before {
    content: "VOICE";
    position: absolute;
    bottom: -7px;
    right: 10px;
    font-size: 70px;
    color: #CCF6EA;
    font-family: "こぶりなゴシック W6 JIS2004", "Avenir Next", Verdana, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, Osaka, sans-serif;
    z-index: 0; }

.voice__item + .voice__item {
  margin-top: 60px; }

@media screen and (max-width: 768px) {
  .voice__item {
    padding: 18px; }
    .voice__item:before {
      bottom: -5px;
      font-size: 50px; }
    .voice__item + .voice__item {
      margin-top: 40px; } }

body {
  font-family: "こぶりなゴシック W3 JIS2004", "Avenir Next", Verdana, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, Osaka, sans-serif;
  color: #333; }

@media screen and (min-width: 769px) {
  body {
    min-width: 1260px; } }

.bold {
  font-family: "こぶりなゴシック W6 JIS2004", "Avenir Next", Verdana, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, Osaka, sans-serif; }

ul, li {
  list-style-type: none; }

img {
  max-width: 100%;
  vertical-align: bottom; }

picture {
  text-align: center;
  display: block; }

a {
  text-decoration: none;
  color: #005AFF;
  transition: opacity .3s; }

a:hover {
  opacity: .6; }

.inner {
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px; }

.inner__narrow {
  max-width: 820px; }

.innner__narrow__700 {
  max-width: 720px; }

.center {
  text-align: center; }

.flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.space-between {
  justify-content: space-between; }

.content {
  margin-top: 100px; }

@media screen and (max-width: 768px) {
  .content {
    margin-top: 24px;
    overflow: hidden; } }

p {
  font-size: 18px;
  line-height: 1.8;
  letter-spacing: .05em; }

@media screen and (max-width: 768px) {
  p {
    font-size: 16px;
    letter-spacing: 0.01em; } }

.visible-phone {
  display: none !important; }

@media screen and (max-width: 768px) {
  .hidden-phone {
    display: none !important; }
  .visible-phone {
    display: block !important; }
  body {
    padding-bottom: 64px; } }
