.contact-header {
	border-top: 8px solid #FD1F77;
	padding: 80px 0 60px;
	font-size: 30px;
	.flex {
		justify-content: center;
		align-items: center;
	}
}
.contact-main-visual {
	margin-top: 50px;
}


.contact__head {
	line-height: 1;
	margin-left: 100px;
}
.contact-mv {
	padding: 73px 0 56px;
}

.contact-body {
	padding: 80px 0 100px;
	background: #F2F2F2;
}
.contact__flow {
	text-align: center;
	margin-bottom: 40px;
}

.form-table {
	border-spacing: 0px 30px;
	border-collapse: separate;
	margin-bottom: 80px;
	th {
		padding-right: 20px;
		font-weight: normal;
		width: 180px;
		padding-top: 7px;
		vertical-align: top;
		text-align: left;
		.label-required, 
		.label-optional {
			margin-left: 5px;
			margin-right: 0;
			float: right;
			line-height: 1;
			display: inline-block;
		}
	}
	.label-required{
		background: #FD1F77;
		color: #fff;
		line-height: 1;
		padding: 4px 4px 2px;
		margin-right: 5px;
		display: inline-block;
	}
	.label-optional{
		background: #005AFF;
		color: #fff;
		line-height: 1;
		padding: 4px 4px 2px;
		margin-right: 5px;
		display: inline-block;
	}
	.label-not-required {
		&:before {
			content:"　　";
			color: transparent;
		}
		margin-right: 5px;
		padding: 4px 4px 2px;
	}
	.input {
		border: 2px solid #B3B3B3;
		border-radius: 5px;
		box-sizing: border-box;
		padding: .2rem 1rem;
		width: 47%;
		background: #fff;
		display: inline-block;
		font-size: 18px;
		line-height: 2;
	}

	.input__max-width {
		width: 100%;
	}
	.input__width80 {
		width: 80%;
	}
	input[type="radio"] {
		margin-right: 3px;
	}
	.flex {
		justify-content: space-between;
	}
	.birth-wrap {
		margin-bottom: 10px;
		.input-wrap {
			width: 33%;
			width: calc(100% / 3 - 10px);
			margin-right: 10px;
			.input {
				margin-right: 5px;
				width: calc(100% - 1em - 5px);
			}
		}
	}
	.pref,
	.zipcode {
		width: 200px;
	}
	.address-input {
		margin-top: 10px;
	}

	label {
		display: inline;
	}
	textarea {
		width: 100% !important;
		height: 300px;
	}
}
.cta__btn {
	font-size: 0;
  vertical-align: top;
}

#back-sp {
  display: none;
}
#back-sp,
#back {
	border-bottom-color: #0086BC;
	margin-right: 60px;
}

.link-toppage {
	margin: 60px 0 80px;
	a {
		color: #000000;
	}
}
#formWrap + .footer,
.contact-body + .footer {
	margin-top: 0;
}


@include mq {
	.contact-header {
		padding: 30px 0 20px;
	}
	.contact-logo{
		img {
			width: 100px;
			display: block;
		}
	}
	.contact__head {
		font-size: 16px;
		margin-left: 20px;
		img {
			height: 20px;
			width: auto;
		}
	}
	.contact-mv {
		padding: 20px 40px;
	}
	.contact-main-visual {
		margin-top: 30px;
	}
	.contact-body {
		padding: 25px 0 40px;
	}
	.contact__flow {
		margin-bottom: 25px;
	}

	.form-table {
		margin-bottom: 20px;
		border-spacing: 0;
		table-layout:fixed;
		width: 100%;
		tr,
		th,
		td {
			display: block;
		}
		th {
			width: auto;
			margin-bottom: 1rem;
			.label-required, 
			.label-optional {
				float: none;
			}
		}
		td {
			padding-top: 5px;
		}
		.input {
			width: 100%;
			font-size: 16px !important;
			margin-bottom: 1rem;
		}
		.input.radio {
			width: auto;
			margin-right: 10px;
		}
		select {
			margin-bottom: 1rem;
		}
		.flex {
			justify-content: flex-start;
		}
		.zipcode {
			width: 50%;
		}
		.label-required {
			margin-left: 5px;
			margin-right: 0;
		}
	}

	#back-sp {
		margin-right: 0;
		margin-top: 20px;
    display:  inline-block;
	}
	.confirm {
		.form-table {
			width: 100%;
			margin-bottom: 20px;
			tr {
				border-top: 1px solid #ddd;
				padding: 10px 0;
				&:first-child {
					border-top: 0;
				}
			}
			th {
				margin-bottom: 0;
			}
		}
	}
	.link-toppage {
		margin: 30px 0;
	}
}


.cta {
  background-size: auto auto;
  background-color: #fde8ef;
  background-image: -webkit-repeating-linear-gradient(154deg, transparent, transparent 6px, #fef4f7 6px, #fef4f7 12px);
  background-image: repeating-linear-gradient(-64deg, transparent, transparent 6px, #fef4f7 6px, #fef4f7 12px);
  padding: 7rem 0;
}

.conclusion {
  margin-bottom: 5rem;
}

.cta__logo {
  margin-bottom: 3rem;
}

.cta__privilege {
  margin-top: 3rem;
  margin-bottom: 5.5rem;
}

.cta__btn a {
  border-bottom: 6px solid #931B5E;
  display: inline-block;
  border-radius: 10px;
}

.cta__btn a:hover {
  border-bottom: 0;
  -webkit-transform: translateY(6px);
  transform: translateY(6px);
}
