.din {
  .main-visual__title {
    font-family: 'Oswald', sans-serif;
  }
}

.align-right{
  text-align: right;
}
.signature {
  line-height: 1.8;
  margin-top: 50px;
}
.date {
  font-size: 14px;
}

.privacy__list {
  dt {
    border-bottom: 1px solid #333;
    font-size: 20px;
    padding-bottom: 14px;
    margin-bottom: 8px;
  }
  dd {
    p {
      font-size: 16px;
    }
  }
  dd + dt {
    margin-top: 40px;
  }
}


.privacy__head {
  border-radius: 16px;
  font-size: 28px;
  text-align: center;
  padding: 40px;
  background: #E6E6E6;
  margin-bottom: 40px;
}

@include mq {
  .privacy {
    &__head {
      font-size: 18px;
      padding: 30px 0;
      border-radius: 10px;
    }
    &__list {
      dt {
        font-size: 18px;
      }
      dd + dt {
        margin-top: 24px;
      }
    }
  }
}