.headline__border {
  border: 4px solid #005AFF;
  color: #005AFF;
  line-height: 1.6;
  letter-spacing: .1em;
  padding: 32px 0;
  text-align: center;
  font-size: 26px;
}

.closed {
  .accordion {
    &__title {
      background: #666666;
    }
  }
}
.accordion {
  &__title {
    align-items: center;
    background: #005AFF;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 24px;
  }

  &__body {
    background: #F2F2F2;
    padding: 36px 36px 60px;
    font-size: 18px;
    line-height: 1.8;
  }

}
.accordion {
  &__text {
    flex-grow: 1;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: 0.1em;
    padding-left: 36px;
  }
}
.accordion__list {
  margin-top: 40px;
}

.accordion-icon {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}

.accordion-arrow {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #fff;
  position: relative;
  transform: rotate(-90deg);
  transition: .3s;
  &:before {
    content: "";
    display: block;
    border: 2px solid #fff;
    width: 14px;
    height: 14px;
    border-top: 0;
    border-left: 0;
    transform:  translateY(-50%) rotate(45deg);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: calc(50% - 3px);
  }
  &:after {

  }
}

.active .accordion-arrow {
  transform: rotate(0deg);
}


.accordion__item + .accordion__item {
  margin-top: 50px;
}

.price {
  font-size: 20px;
}
.price-title {
  border: 2px solid #000000;
  border-radius: 5px;
  letter-spacing: .2em;
  line-height: 1;
  padding: 18px 20px;
  margin-right: 20px;
}
.price-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.price__num {
  font-size: 26px;
  margin-right: 5px;
}
.price__tax {
  font-size: 16px;
}

.price__item {
  line-height: 1.4;
}
.red {
  color: #FD1F77;
}

.option-link__list {
  font-size: 16px;
  display: inline-block;
  margin-top: 70px;
  text-align: left;
  a {
    color: #333;
  }
}

.external-link {
  display: flex;
  align-items: center;
  line-height: 1.8;
  &:before {
    content: "";
    display: block;
    width: 18px;
    height: 16px;
    background: url(../../images/pc/option/icon-externallink.png) no-repeat center center / contain;
    margin-right: 5px;
    //margin-top: 7px;
  }
}

.align-top {
  align-items: flex-start;
  .price-title  {
    margin-top: 10px;
  }
}

.inline__align-left { 
  display: inline-block;
  margin-top: 56px;
  text-align: left;
}


.anchor-nav {
  margin-top: 40px;
  margin-bottom: 100px;
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -40px -20px 0;
  }
  &__item {
    font-size: 22px;
    width: 50%;
    padding: 0 20px;
    margin-top: 40px;
    box-sizing: border-box;
    a {
      background: #E6E6E6;
      color: #333;
      display: block;
      padding: 24px 0;
      text-align: center;
      border-radius: 8px;
      transition: .3s;
      &:hover {
        background: #005AFF;
        color: #fff;
      }
    }
  }
}

@include mq {
  .section__title__full {
    margin: 0 -10px;
  }
  .anchor-nav {
    margin-bottom: 30px;
    &__item {
      font-size: 16px;
      margin-top: 24px;
      width: 80%;
    }
  }
  .headline__border {
    font-size: 18px;
    padding: 20px 0;
  }
  .accordion {
    &__list {
      margin-top: 12px;
    }
    &__title {
      padding: 12px;
    }
    &__text {
      font-size: 18px;
      padding-left: 8px;
      small {
        display: block;
        font-size: 14px;
      }
    }
    &__body {
      padding: 14px 14px 45px;
    }
    &__item {
      + .accordion__item {
        margin-top: 12px;
      }
    }
  }
  .accordion-icon {
    width: 25px;
    height: 25px;
  }
  .accordion-arrow {
    width: 25px;
    height: 25px;
    border-width: 1px;
    &:before {
      border-width: 1px;
      width: 9px;
      height: 9px;
      top: calc(50% - 2px);
    }
  }

  .price-title {
    margin-right: 0;
  }
  .price-wrap {
    margin-top: 18px;
  }
  .price {
    font-size: 16px;
    margin-top: 16px;
    width: 100%;
    &__item {
      text-align: center;
      + .price__item {
        margin-top: 8px;
      }
    }
    &__num {
      font-size: 22px;
    }
    &__tax {
      font-size: 12px;
    }
  }

  .option-link {
    &__list {
      margin-top: 40px;
    }
    &__item {
      font-size: 13px;
      + .option-link__item {
        margin-top: 5px;
      }
    }
  }
  .external-link{
    display: block;
    &:before {
      display: inline-block;
      width: 13px;
      height: 12px;
      margin-top: 5px;
    }
  }
}