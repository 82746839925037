.din{
  font-family: 'Oswald', sans-serif;
}

.faq {
  &__question {
    align-items: center;
    background: #00D195;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 24px;
  }

  &__answer {
    background: #D9F8EF;
    padding: 36px;
    font-size: 18px;
    line-height: 1.8;
  }

}
.question {
  &__text {
    flex-grow: 1;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: 0.1em;
    padding-left: 36px;
  }
}

.question-icon {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width:65px;
  height: 65px;
  border: 3px solid #fff;
  border-radius: 10px;
  font-size: 40px;
  color: #fff;
  padding-bottom: 4px;
}
.question-arrow {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid #fff;
  position: relative;
  transform: rotate(-90deg);
  transition: .3s;
  &:before {
    content: "";
    display: block;
    border: 3px solid #fff;
    width: 14px;
    height: 14px;
    border-top: 0;
    border-left: 0;
    transform:  translateY(-50%) rotate(45deg);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: calc(50% - 3px);
  }
  &:after {

  }
}

.active .question-arrow {
  transform: rotate(0deg);
}

.faq__item + .faq__item {
  margin-top: 48px;
}


.toggle-container {
  transition: height 0.35s ease-in-out;
  overflow: hidden;
}
.toggle-container:not(.active) {
  display: none;
}


@include mq {
  .faq {
    &__question {
      //align-items: flex-start;
      padding: 12px 14px;
    }
    &__answer {
      font-size: 16px;
      padding: 12px 14px;
    }
    &__item {
      + .faq__item {
        margin-top: 24px;
      }
    }
  }
  .question {
    &-icon {
      font-size: 20px;
      height: 38px;
      width: 38px;
      border-width: 2px;
    }
    &__text {
      box-sizing: border-box;
      font-size: 16px;
      padding-left: 8px;
      width: calc(100% - 38px - 40px);
      flex-grow: unset;
    }
    &-arrow {
      border-width: 2px;
      height: 30px;
      width: 30px;
        box-sizing: border-box;
      &:before {
        height: 9px;
        width: 9px;
        top: calc(50% - 2px);
        border-width: 2px;
      }
    }
  }
}